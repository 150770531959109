import React, {PureComponent, useState} from 'react';
import { Formik, Form, FormikActions } from 'formik';
import { connect, MapStateToProps, MapDispatchToProps } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

import {login, logout} from 'store/app/actions';
import messages from 'lib/Messages';
import {defaultHost, xmpp} from 'lib/XMPP';

import { Button } from 'components/Button';
import {
  Title,
  Header,
  LoginCard,
  ShadowInput,
  LoginWrapper,
  ButtonsWrapper,
} from './styles';
import { Redirect, RouteProps ,withRouter } from 'react-router';
import IStore from 'types/IStore';

interface IOwnProps {
  dispatch: Dispatch;
}

interface IStateToProps {
  userName: string | null;
  password: string | null;
  serverName: string | null;
  serverDomain : string | null;
  clientDomain : string | null;
}

interface IDispatchToProps {
  login: (
      password: string | null,
      serverName: string | null,
      userName: string | null,
      serverDomain: string | null,
      clientDomain: string | null,
  ) => any;
}

type IComponentProps = IOwnProps &
    IStateToProps &
    IDispatchToProps &
    RouteProps;

interface ILoginValues {
  serverName: string;
  userName: string;
  password: string;
}

interface IState {
  doLogout: boolean
  loading: boolean
}


class Login extends PureComponent<IComponentProps,IState> {
  state = {
    doLogout : false,
    loading: false
  };
  handleLogin = (
      values: ILoginValues,
      formikActions: FormikActions<ILoginValues>
  ) => {
    const { userName:umix, password, serverName:smix } = values;
    const userName=umix.split('@')[0]
    const clientDomain = umix.split('@')[1] || defaultHost
    const serverName=smix.split('@')[0]
    const serverDomain = smix.split('@')[1] || defaultHost
    if (userName.trim() !== '' && password.trim() !== '') {
      xmpp
          .login(
              this.props.dispatch,
              password,
              serverName.toLowerCase(),
              userName.toLowerCase(),
              serverDomain.toLowerCase(),
              clientDomain.toLowerCase()
          )
          .then(() => {
            this.props.login(
                password,
                serverName.toLowerCase(),
                userName.toLowerCase(),
                serverDomain.toLowerCase(),
                clientDomain.toLowerCase()
            );
          })
          .catch((message: string) => {
            if (message) {
              alert(message);
            }
            this.setState({ loading: false });
          });
    } else {
      alert('Please enter valid credential');
      this.setState({ loading: false });
    }
  };

  render() {
    const { from } = this.props.location!!.state || { from: { pathname: '/' } };

    const initialValues: ILoginValues = {
      serverName: (this.props.serverName || '') + '@'+(this.props.serverDomain || 'jabb.im'),
      userName: (this.props.userName || '') + '@' + (this.props.clientDomain || 'jabb.im'),
      password: this.props.password || '',
    };


    if(this.state.doLogout)
      return <Redirect to='/login' />
    return (
          <div>
            <Header>
              <Title children={messages.smartHomeSystem} />
            </Header>
            <h1> {messages.settings} </h1>
            <div className="section">
              <div>
                <div id="login">
                  <Formik
                      initialValues={initialValues}
                      onSubmit={this.handleLogin}
                  >
                    {({ values, handleChange, handleBlur, isSubmitting }) => (
                        <Form>
                          <ShadowInput
                              id="serverName"
                              name="serverName"
                              type="text"
                              required={true}
                              placeholder={messages.server}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.serverName}
                          />
                          <ShadowInput
                              id="userName"
                              name="userName"
                              type="text"
                              required={true}
                              placeholder={messages.userName}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.userName}
                          />
                          <ShadowInput
                              id="password"
                              name="password"
                              type="password"
                              required={true}
                              placeholder={messages.password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                          />
                          <ButtonsWrapper>
                            <Button
                                type="submit"
                                color="blue"
                                children={messages.save}
                                loading={isSubmitting}
                            />
                          </ButtonsWrapper>
                          <ButtonsWrapper>
                            <Button
                                type='button'
                                onClick={() => {
                                  xmpp.disconnect();
                                  this.props.dispatch(logout());
                                  this.setState({doLogout:true})
                                }}
                            >
                              {messages.logout}
                            </Button>
                          </ButtonsWrapper>
                        </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
    );
  }
}

const mapStateToProps: MapStateToProps<IStateToProps, IOwnProps, IStore> = (
    state
) => {
  const userName = state.app.userName;
  const password = state.app.password;
  const serverName = state.app.serverName;
  const serverDomain = state.app.serverDomain;
  const clientDomain = state.app.clientDomain;
  return { userName,
    password,
    serverName,serverDomain,clientDomain };
};

const mapDispatchToProps: MapDispatchToProps<IDispatchToProps, IOwnProps> = (
    dispatch
) => {
  return bindActionCreators({ login,dispatch }, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
