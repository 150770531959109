import React, { StatelessComponent } from 'react';
import { connect, MapStateToProps } from 'react-redux';

import IPlace from 'types/IPlace';
import IStore from 'types/IStore';
import IDevices from 'types/IDevices';
import messages from 'lib/Messages';
import { colors } from 'utils/theme/colors';

import { Card, CardBody, ImageIcon } from './styles';


import tv from 'assets/images/icons2/tv-active.png';
import bed from 'assets/images/icons2/bed-active.png';
import toilet from 'assets/images/icons2/toilet-active.png';
import washing from 'assets/images/icons2/washing-active.png';
import theWay from 'assets/images/icons2/the-way-active.png';
import watering from 'assets/images/icons2/fountain-active.png';
import elevator from 'assets/images/icons2/elevator-active.png';
import music from 'assets/images/icons2/music-active.png';
import lock from 'assets/images/icons2/lock-active.png';
import star from 'assets/images/icons2/star-active.png';
import unknown from 'assets/images/icons/unknown-dark.png';

interface IOwnProps {
  place: IPlace;
}

interface IStateToProps {
  devices: IDevices;
}

type IComponentProps = IOwnProps & IStateToProps;

const requireIcon = (place: IPlace) => {
  if (place.iconNumber === '1') {
    return tv;
  } else if (place.iconNumber === '2') {
    return bed;
  } else if (place.iconNumber === '3') {
    return toilet;
  } else if (place.iconNumber === '4') {
    return washing;
  }else if (place.iconNumber === '5') {
    return theWay;
  }else if (place.iconNumber === '6') {
    return watering;
  }else if (place.iconNumber === '7') {
    return elevator;
  }else if (place.iconNumber === '8') {
    return music;
  }else if (place.iconNumber === '9') {
    return lock;
  }else if (place.iconNumber === '10') {
    return star;
  }
  return unknown;
};

const PlaceCard: StatelessComponent<IComponentProps> = ({ place, devices }) => {
  const icon = requireIcon(place);
  const devicesNumber = place.devices.reduce(
    (sum: number, deviceId: string) => {
      Object.keys(devices[deviceId]).forEach(() => {
        sum += 1;
      });
      return sum;
    },
    0
  );
  return (
    <Card to={`/place/${place.name}/devices`}>
      <CardBody>
        <div>
          <p>{place.name}</p>
          <p style={{ color: colors.smoke, fontSize: 10 }}>
            {devicesNumber > 0
              ? `${devicesNumber} ${messages.device}`
              : `${messages.without} ${messages.device}`}
          </p>
        </div>
        <ImageIcon src={icon} />
      </CardBody>
    </Card>
  );
};

const mapStateToProps: MapStateToProps<IStateToProps, IOwnProps, IStore> = (
  state
) => {
  const devices: IDevices = state.devices;
  return { devices };
};

export default connect(mapStateToProps)(PlaceCard);
