import React from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { Dispatch } from 'redux';
import { RouteProps } from 'react-router';

import IStore from 'types/IStore';
import IPlaces from 'types/IPlaces';
import { xmpp } from 'lib/XMPP';

import { StatusBox } from 'components/StatusBox';
import PlacesList from 'components/PlacesList';
import { login } from 'store/app/actions';
import ScenarioList from "../../components/ScenarioList";

interface IOwnProps {
  dispatch: Dispatch;
}

interface IStateToProps {
  connected: boolean;
  password: string | null;
  places: IPlaces;
  scenario: number | undefined
  serverName: string | null;
  userName: string | null;
  serverDomain: string | null;
  clientDomain: string | null;
}

type IComponentProps = IOwnProps & IStateToProps & RouteProps;

interface IComponentStates {
  selectedPlace: string | null;
}

export class HomePage extends React.PureComponent<
  IComponentProps,
  IComponentStates
> {
  state = {
    selectedPlace: null,
  };

  componentDidMount = async () => {
    const { connected, password, serverName, userName , serverDomain,clientDomain , dispatch } = this.props;
    if (!connected && password && serverName && userName) {
      xmpp.login(dispatch, password, serverName, userName,serverDomain,clientDomain).then(() => {
        dispatch(login(password, serverName, userName,serverDomain,clientDomain));
        // this.getData();
      });
    }
  };

  // getData = () => {
  //   xmpp.getPlaces();
  // };

  render() {
    const { places, scenario } = this.props;

    return (
      <React.Fragment>
        <StatusBox />
        <ScenarioList
            currentScenario={scenario }
        />
        <PlacesList places={places} />
      </React.Fragment>
    );
  }
}

const mapStateToProps: MapStateToProps<IStateToProps, IOwnProps, IStore> = (
  state
) => {
  const places = state.places;
  const password = state.app.password;
  const serverName = state.app.serverName;
  const userName = state.app.userName;
  const serverDomain = state.app.serverDomain;
  const clientDomain = state.app.clientDomain;
  const scenario = state.app.scenario;
  const connected = state.connection.internet;

  return { places, password, serverName, userName, connected ,scenario, serverDomain,clientDomain};
};

export default connect(mapStateToProps)(HomePage);
