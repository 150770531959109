export default {
  activeDevices: 'دستگاه‌های فعال',
  close: 'بستن',
  settings: 'تنظیمات',
  logout: 'خروج',
  scenarios: 'وضعیت‌ها',
  serverSample: 'jabb.im',
  configurationOf: (deviceName: string) => `پیکربندی ${deviceName}`,
  cooling: 'سرمایش',
  heating: 'گرمایش',
  device: 'دستگاه',
  devicesOf: (placeName: string) => `دستگاه‌های ${placeName}`,
  enter: 'ورود',
  save: 'ثبت',
  networkConnection: 'اتصال اینترنت',
  open: 'باز کردن',
  pageNotFound: 'صفحه پیدا نشد',
  password: 'گذرواژه',
  pause: 'توقف',
  rooms: 'مکان‌ها',
  server: 'سرور',
  speed: 'سرعت',
  smartHomeSystem: 'سامانه‌ی خانه‌ی هوشمند',
  userName: 'نام کاربری',
  without: 'بدون',
  cinema: 'سینما',
  exit: 'خروج',
  guest: 'مهمان',
  normal: 'عادی',
  sleep: 'خواب',
  travel: 'سفر',
};
