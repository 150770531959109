import React from 'react';
import ReactDOM from 'react-dom';

import 'assets/styles/global.css';
import 'assets/styles/simple-grid.css';

import { App } from './containers/App/App';

ReactDOM.render(<App />, document.getElementById('root'));
// serviceWorker.register();

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('./service-worker.js', {scope: '/'})
      .then((reg) => {
        // registration worked
        console.log('Registration succeeded. Scope is ' + reg.scope);
      }).catch((error) => {
    // registration failed
    console.log('Registration failed with ' + error);
  });
}

