import IDevice from 'types/IDevice';
import {Dispatch} from "redux";
import {sendRequest} from "../store/devices/actions";

export const changeLampState = (lamp: IDevice, dispatch: Dispatch): string => {
  const newA = lamp.status === 'A' ? (lamp.active === 'on' ? '0' : '1') : '2';
  const newB = lamp.status === 'B' ? (lamp.active === 'on' ? '0' : '1') : '2';
  dispatch(sendRequest(lamp.number))
  return `K-V-${lamp.number}-${newA}-${newB}-48-48-0-0`;
};

export const changeThermostatState = (thermostat: IDevice, odd: boolean = false, dispatch: Dispatch, setTemp = false): string => {
  const cooling = thermostat.cooling ? 1 : 0;
  const active = thermostat.active === 'on' ? 1 : 0;
  let result = `K-V-${thermostat.number}-${odd? 4:  thermostat.fanSpeed }-${setTemp? 0 : odd ? 1 : active}-${
      thermostat.temperature
  }-${thermostat.toTemperature}-${cooling}-0`;
  dispatch(sendRequest(thermostat.number))
  // thermostat.fetching=true
  return result;
};

export const changeCurtainState = (
  curtain: IDevice,
  state: 'open' | 'pause' | 'close'
): string => {
  let stateNumber = '0-0'; // pause
  if (state === 'open') {
    stateNumber = '1-2';
  } else if (state === 'close') {
    stateNumber = '2-1';
  }
  return `K-V-${curtain.number}-${stateNumber}-48-48-0-0`;
};
