import React from 'react';
import {Redirect, RouteComponentProps} from 'react-router';
import {MapStateToProps, connect, MapDispatchToProps} from 'react-redux';

import IStore from 'types/IStore';
import IDevices from 'types/IDevices';
import messages from 'lib/Messages';
import IDevice from 'types/IDevice';
import mapDeviceType from 'lib/mapDeviceType';
import { changeLampState } from 'lib/deviceUtils';

import DeviceCard from 'components/DeviceCard';
import { xmpp } from 'lib/XMPP';
import ThermostatModal from 'components/ThermostatModal';
import CurtainModal from 'components/CurtainModal';
import {bindActionCreators, Dispatch} from "redux";
import {login} from "../../store/app/actions";
import IPlaces from "../../types/IPlaces";
import {Loading} from "../../components/Loading";

interface IParams {
  placeId: string;
}

interface IStateToProps {
  devices: IDevices;
  deviceList: string[];
  places : any
}

interface IDispatchToProps {
  dispatch: Dispatch;
}

interface IOwnProps extends RouteComponentProps<IParams> {}

type IComponentProps = IOwnProps & IStateToProps & IDispatchToProps;

interface IComponentStates {
  thermostatModal: null | IDevice;
  curtainModal: null | IDevice;
}

class DevicesPage extends React.PureComponent<
  IComponentProps,
  IComponentStates
> {
  state = {
    thermostatModal: null,
    curtainModal: null,
  };

  handleClickOnDevice = (device: IDevice) => () => {
    const deviceType = mapDeviceType(device.type);
    if (deviceType === 'lamp') {
      if(!device.fetching) {
        const message = changeLampState(device, this.props.dispatch);
        xmpp.updateDeviceStatus(message);
      }
    } else if (deviceType === 'thermostat') {
      this.setState({ thermostatModal: device, curtainModal: null });
    } else if (deviceType === 'curtain') {
      this.setState({ curtainModal: device, thermostatModal: null });
    }
  };

  handleCloseModal = () => {
    this.setState({ thermostatModal: null, curtainModal: null });
  };

  render() {
    const { match, deviceList, devices ,places} = this.props;
    const { thermostatModal, curtainModal } = this.state;
    if(Object.values(places).length == 0)
        return <Redirect to='/' />;
    return (
      <React.Fragment>
        <h3>{messages.devicesOf(match.params.placeId)}</h3>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {deviceList.map((deviceId) => {
            const deviceMap = devices[deviceId];
            if (!deviceMap) {
              return null;
            }
            return (
              <React.Fragment key={deviceId}>
                {Object.values(deviceMap).map((device) => (
                  <DeviceCard
                    key={`${device.number}-${device.name}`}
                    device={device}
                    onClick={this.handleClickOnDevice(device)}
                  />
                ))}
              </React.Fragment>
            );
          })}
        </div>
        <section>
          {thermostatModal && (
            <ThermostatModal
              device={
                devices[(thermostatModal as IDevice).number][
                  (thermostatModal as IDevice).status
                ]
              }
              onClose={this.handleCloseModal}
              dispatch={this.props.dispatch}
            />
          )}
          {curtainModal && (
            <CurtainModal
              device={
                devices[(curtainModal as IDevice).number][
                  (curtainModal as IDevice).status
                ]
              }
              onClose={this.handleCloseModal}
            />
          )}
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps: MapStateToProps<IStateToProps, IOwnProps, IStore> = (
  state,
  props,
) => {
  const devices = state.devices;
  const placeName = props.match.params.placeId;
  const place = state.places[placeName];
  let deviceList: string[] = [];
  if (place) {
    deviceList = place.devices;
  }

  return { devices, deviceList , places: state.places };
};

const mapDispatchToProps: MapDispatchToProps<IDispatchToProps,IOwnProps> = (
    dispatch : Dispatch
) => {
  return bindActionCreators({ dispatch }, dispatch);
};

export default connect(mapStateToProps,mapDispatchToProps)(DevicesPage);
