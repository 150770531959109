import React, { StatelessComponent } from 'react';


import IScenario from "./IScenario";
import messages from 'lib/Messages';


import weatherLight from 'assets/images/icons2/weather-active.png'
import weatherDark from 'assets/images/icons2/weather-dark.png'
import foodLight from 'assets/images/icons2/food-active.png'
import foodDark from 'assets/images/icons2/food-dark.png'
import nightLight from 'assets/images/icons2/night-active.png'
import nightDark from 'assets/images/icons2/night-dark.png'
import cinemaLight from 'assets/images/icons2/cinema-active.png'
import cinemaDark from 'assets/images/icons2/cinema-dark.png'
import exitLight from 'assets/images/icons2/exit-active.png'
import exitDark from 'assets/images/icons2/exit-dark.png'
import flightLight from 'assets/images/icons2/flight-active.png'
import flightDark from 'assets/images/icons2/flight-dark.png'
import ScenarioCard from "../ScenarioCard";
import {xmpp} from "../../lib/XMPP";
interface IComponentProps {
    currentScenario: number | string | undefined;
}

const scenarios: IScenario[] = [
    { id: 1, title: messages.normal , activeIcon: weatherLight, icon: weatherDark},
    { id: 2, title: messages.guest  , activeIcon: foodLight, icon: foodDark},
    { id: 3, title: messages.sleep  , activeIcon: nightLight, icon: nightDark},
    { id: 4, title: messages.cinema  , activeIcon: cinemaLight, icon: cinemaDark},
    { id: 5, title: messages.exit  , activeIcon: exitLight, icon: exitDark},
    { id: 6, title: messages.travel  , activeIcon: flightLight, icon: flightDark},
];

const ScenarioList: StatelessComponent<IComponentProps> = ({ currentScenario }) => {

    const onPress = (id:number)=>{
        const message = id.toString();
        xmpp.updateScenario(message);
    };

    return (
        <React.Fragment>
            <h3>{messages.scenarios}</h3>
            <div
                style={{
                    flexShrink:0,
                    overflowX: 'auto',
                    display: 'flex'
                }}
            >
                {Object.values(scenarios).map((scenario) => (
                    <ScenarioCard
                        key={scenario.id}
                        scenario={scenario}
                        active={Number(currentScenario) === scenario.id}
                        onClick={()=>{onPress(scenario.id)}}
                    />

                ))}
            </div>
        </React.Fragment>
    );
};

export default ScenarioList;
