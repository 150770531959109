import React from 'react';


import { Card, CardBody, ImageIcon } from './styles';

import IScenario from "../ScenarioList/IScenario";

interface IComponentProps {
  scenario: IScenario;
  active: boolean
  onClick: () => void;
}


const ScenarioCard: React.StatelessComponent<IComponentProps> = ({
                                                                   scenario, active,
  onClick,
}) => {
  const icon = active ? scenario.activeIcon: scenario.icon;
  return (
    <Card onClick={onClick} active={active}>
      <CardBody>
        <ImageIcon src={icon} />
        <p>{scenario.title}</p>
      </CardBody>
    </Card>
  );
};

export default ScenarioCard;
